
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandHoliday from '@/mixins/mixinDemandHoliday';
import CommonProgress from "@/components/common/Progress.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonProgress,
    CommonButton,
  }
})
export default class Row extends Mixins(mixinDemandHoliday) {
  @Prop() demand_holiday;

  delete_demand_holiday(demand_holiday_id) {
    this.m.deleteDemandHoliday({demand_holiday_id});
  }
}

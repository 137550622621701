
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinDemandHoliday from '@/mixins/mixinDemandHoliday';
import DemandholidaySpmain from "@/components/demandholiday/Spmain.vue";
import DemandholidayMain from "@/components/demandholiday/Main.vue";

@Component({
  components: {
    DemandholidaySpmain,
    DemandholidayMain,
  }
})
export default class DemandHoliday extends Mixins(mixinDemandHoliday) {

}

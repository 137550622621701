import { Component, Vue, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import manager from '@/vuex/demand_holiday/manager';
import global from "@/vuex/demand_holiday/global";

@Component
export default class mixinDemandHoliday extends Mixins(utilMixins) {
  get is_set_global(): boolean {
    return global.is_set;
  }
  get is_valid_global(): boolean {
    return global.is_valid;
  }
  async get_global() {
    await global.fetch();
  }
  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) 
      return global.data;
  }

  get m() {
    return manager;
  }


  get demand_holidays() {
    return this.global.demand_holidays;
  }


  get sort_date() {
    return this.query.demand_holiday_sort_date;
  }
  set sort_date(val) {
    if (val == '' || val == null) {
      this.query = {demand_holiday_sort_date: undefined};
    } else {
      this.query = {demand_holiday_sort_date: val};
    }
  }
  get sort_kind() {
    return this.query.demand_holiday_sort_kind;
  }
  set sort_kind(val) {
    if (val == '' || val == null) {
      this.query = {demand_holiday_sort_kind: undefined};
    } else {
      this.query = {demand_holiday_sort_kind: val};
    }
  }
  get sort_status() {
    return this.query.demand_holiday_sort_status;
  }
  set sort_status(val) {
    if (val == '' || val == null) {
      this.query = {demand_holiday_sort_status: undefined};
    } else {
      this.query = {demand_holiday_sort_status: val};
    }
  }
  
  get page() {
    var page = this.query.demand_holiday_page;
    if (page === undefined || page == '') {
      this.query = {demand_holiday_page: 1};;
      page = 1;
    }
    return page;
  }
  set page(val) {
    if (val == '' || val == null) {
      this.query = {demand_holiday_page: undefined};
    } else {
      this.query = {demand_holiday_page: val};
    }
  }

  get searched_sorted_demand_holidays() {
    var demand_holidays = util.deep_copy(this.demand_holidays);
    demand_holidays = this.sort_demand_holidays(demand_holidays);
    return demand_holidays;
  }
  get managed_demand_holidays() {
    var demand_holidays = util.deep_copy(this.searched_sorted_demand_holidays);
    demand_holidays = this.page_demand_holidays(demand_holidays);
    return demand_holidays;
  }
  sort_demand_holidays(demand_holidays) {
    if (this.sort_date)
      demand_holidays.sort((x, y) => util.sort_idx(x.date ? util.date2obj(x.date).getTime() : 0, y.sort_date ? util.date2obj(y.date).getTime() : 0, this.sort_date));
    else if (this.sort_kind)
      demand_holidays.sort((x, y) => util.sort_idx(x.kind, y.kind, this.sort_kind));
    else if (this.sort_status)
      demand_holidays.sort((x, y) => util.sort_idx(x.status, y.status, this.sort_status));
    return demand_holidays;
  }
  page_demand_holidays(demand_holidays) {
    return demand_holidays.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }
  sort_click(sort_key) {
    this.page = 1;
    sort_key = 'sort_'+sort_key;
    var sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = 'asc';
    } else if (this[sort_key] == 'asc') {
      sort_mode = 'desc';
    } else if (this[sort_key] == 'desc') {
      sort_mode = 'asc';
    }
    this.set_sort(sort_key, sort_mode);
  }
  set_sort(sort_key, sort_mode) {
    console.log(sort_key, sort_mode);
    this.query = {
      demand_holiday_sort_date: sort_key == 'sort_date' ? sort_mode: undefined,
      demand_holiday_sort_kind: sort_key == 'sort_kind' ? sort_mode: undefined,
      demand_holiday_sort_status: sort_key == 'sort_status' ? sort_mode: undefined,
    };
  }



  get disp_num() {
    return manager.disp_num;
  }
  set disp_num(val) {
    this.page = 1;
    util.setLocalStorage('demand_holiday_disp_num', val);
    manager.setDispNum(val);
  }
}
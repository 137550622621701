
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDemandHoliday from "@/mixins/mixinDemandHoliday";
  import dialog from "@/vuex/dialog";
  import DemandholidayRow from "@/components/demandholiday/Row.vue";

  @Component({
    components: {
      DemandholidayRow,
    },
  })
  export default class Table extends Mixins(mixinDemandHoliday) {
    scrollTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0,
      );
    }
  }

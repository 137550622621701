
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinDemandHoliday from '@/mixins/mixinDemandHoliday';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import CommonDate from "@/components/common/Date.vue";
import CommonSelect from "@/components/common/Select.vue";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    UtilPopup,
    CommonDate,
    CommonSelect,
    CommonText,
  }
})
export default class Main extends Mixins(mixinDemandHoliday) {
  get is_display() {
    return this.m.is_open_add_demand_holiday_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenAddDemandHolidayDialog(val);
  }

  get tmp_demand_holiday() {
    return this.m.demand_holiday;
  }
  set tmp_demand_holiday(val) {
    this.m.setDemandHoliday({demand_holiday: val});
  }

  create_demand_holiday() {
    this.m.createDemandHoliday();
  }
}

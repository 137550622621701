
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandHoliday from '@/mixins/mixinDemandHoliday';
import CommonProgress from "@/components/common/Progress.vue";
import CommonButton from "@/components/common/Button.vue";
import DemandholidayDialogSpadd from "@/components/demandholiday/dialog/Spadd.vue";

@Component({
  components: {
    CommonProgress,
    CommonButton,
    DemandholidayDialogSpadd,
  }
})
export default class Main extends Mixins(mixinDemandHoliday) {
  add_demand_holiday() {
    this.tmp_demand_holiday = {
      date: null,
      kind: 0,
      hourly_paidhol: 1,
      comment: null,
    };
    this.m.setIsOpenAddDemandHolidayDialog(true);
  }

  get tmp_demand_holiday() {
    return this.m.demand_holiday;
  }
  set tmp_demand_holiday(val) {
    this.m.setDemandHoliday({demand_holiday: val});
  }

  delete_demand_holiday(demand_holiday_id) {
    this.m.deleteDemandHoliday({demand_holiday_id});
  }
}


  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDemandHoliday from "@/mixins/mixinDemandHoliday";
  import CommonProgress from "@/components/common/Progress.vue";
  import CommonButton from "@/components/common/Button.vue";
  import DemandholidayPagerHead from "@/components/demandholiday/pager/Head.vue";
  import DemandholidayTable from "@/components/demandholiday/Table.vue";
  import DemandholidayPagerFoot from "@/components/demandholiday/pager/Foot.vue";
  import DemandholidayDialogAdd from "@/components/demandholiday/dialog/Add.vue";

  @Component({
    components: {
      CommonProgress,
      CommonButton,
      DemandholidayPagerHead,
      DemandholidayTable,
      DemandholidayPagerFoot,
      DemandholidayDialogAdd,
    },
  })
  export default class Main extends Mixins(mixinDemandHoliday) {
    add_demand_holiday() {
      this.tmp_demand_holiday = {
        date: null,
        kind: 0,
        hourly_paidhol: 1,
        comment: null,
      };
      this.m.setIsOpenAddDemandHolidayDialog(true);
    }

    get tmp_demand_holiday() {
      return this.m.demand_holiday;
    }
    set tmp_demand_holiday(val) {
      this.m.setDemandHoliday({ demand_holiday: val });
    }
  }
